import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const Wrapper = styled(Link)`
	text-decoration-line: ${props =>
		props.noUnderline ? "none" : "underline"};
	text-underline-position: under;
	color: #00587c;
`

export const InternalLink = ({ to, children, ...rest }) => {
	return (
		<Wrapper to={to} {...rest}>
			{children}
		</Wrapper>
	)
}
