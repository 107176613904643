import React from "react"
import styled from "styled-components"

import { COLOR_MAIN } from "style/colors"

const SVG = styled.svg``
export const Logo = ({ color = COLOR_MAIN, ...rest }) => {
	return (
		<SVG
			width="398"
			height="223"
			viewBox="0 0 398 223"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path d="M19.6594 163.005L0 223H325.073L370.836 83.0944H116.488L125.95 54.2807H380.38L398 0H72.9272L29.0404 133.947L255.164 134.029L245.783 162.924L19.6594 163.005Z" />
		</SVG>
	)
}
