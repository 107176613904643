import { css } from "styled-components"

/* Styling utilities, should be copy-pastable into other apps without requiring anything more than styled-components */
export const utility = {
	visuallyHidden: () => css`
		position: absolute !important;
		height: 1px;
		width: 1px;
		overflow: hidden;
		clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
		clip: rect(1px, 1px, 1px, 1px);
		white-space: nowrap; /* added line */
	`,
	button: () => css`
		display: flex;
		align-items: center;
		border: 0;
		margin: 0;
		padding: 0;
		background: none;
		text-decoration: none;
		cursor: pointer;
	`,
	image: () => css`
		max-width: 100%;
		height: auto;
	`,
}
