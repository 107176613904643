import { css } from "styled-components"

export const STYLE = {
	TEXT: {
		MOBILE: {
			TITLE_MAIN: css`
				font-family: Roboto;
				font-style: italic;
				font-weight: bold;
				font-size: 30px;
				line-height: 35px;
				text-align: right;
				text-transform: uppercase;
			`,
			TITLE_MAIN_2: css`
				font-family: Roboto;
				font-style: italic;
				font-weight: 500;
				font-size: 30px;
				line-height: 35px;
				text-align: right;
				text-transform: uppercase;
			`,
			TITLE: css`
				font-family: Inter;
				font-style: normal;
				font-weight: 900;
				font-size: 28px;
				line-height: 38px;
			`,
			TITLE_SMALL: css`
				font-family: Inter;
				font-style: normal;
				font-weight: bold;
				font-size: 22px;
				line-height: 34px;
			`,
			POP_TITLE: css`
				font-family: Inter;
				font-style: normal;
				font-weight: 900;
				font-size: 28px;
				line-height: 38px;
				text-align: center;
			`,
			TEXT: css`
				font-family: Inter;
				font-style: normal;
				font-weight: 300;
				font-size: 17px;
				line-height: 25px;
			`,
			TEXT_SMALL: css`
				font-family: Inter;
				font-style: normal;
				font-weight: 400;
				font-size: 17px;
				line-height: 25px;
			`,
			TEXT_NEGATIVE: css`
				font-family: Inter;
				font-style: normal;
				font-weight: normal;
				font-size: 17px;
				line-height: 25px;
				text-align: center;
			`,
			CAPTION: css`
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 17px;
				text-transform: uppercase;
			`,
			COPYRIGHT: css`
				font-family: Inter;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 18px;
				text-align: center;
			`,
			PHONE: css`
				font-family: Inter;
				font-style: normal;
				font-weight: bold;
				font-size: 36px;
				line-height: 32px;
				text-align: center;
			`,
			FOOTER_LINK: css`
				font-family: Inter;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 32px;
				text-align: center;
				text-decoration-line: underline;
				text-underline-position: under;
			`,
			MENU: css`
				font-family: Inter;
				font-style: normal;
				font-weight: 900;
				font-size: 28px;
				line-height: 32px;
				display: flex;
				align-items: center;
				text-align: center;
				text-transform: uppercase;
			`,
			BUTTON_SMALL: css`
				font-family: Inter;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
			`,
			BUTTON_LARGE: css`
				font-family: Inter;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 34px;
				text-align: center;
				align-items: center;
				justify-content: center;
			`,
		},
		DESKTOP: {
			TITLE_MAIN: css`
				font-family: Roboto;
				font-style: italic;
				font-weight: 900;
				font-size: 60px;
				line-height: 52px;
				text-align: right;
				text-transform: uppercase;
			`,
			TITLE: css`
				font-family: Inter;
				font-style: normal;
				font-weight: 900;
				font-size: 36px;
				line-height: 52px;
			`,
			TITLE_SMALL: css`
				font-family: Inter;
				font-style: normal;
				font-weight: bold;
				font-size: 22px;
				line-height: 34px;
			`,
			POP_TITLE: css`
				font-family: Inter;
				font-style: normal;
				font-weight: 900;
				font-size: 36px;
				line-height: 52px;
			`,
			TEXT: css`
				font-family: Inter;
				font-style: normal;
				font-weight: 300;
				font-size: 20px;
				line-height: 34px;
			`,
			TEXT_SMALL: css`
				font-family: Inter;
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 32px;
			`,
			CAPTION: css`
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 15px;
				line-height: 17px;
				text-transform: uppercase;
			`,
			FOOTER_LINK: css`
				font-family: Inter;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 32px;
				text-align: center;
				text-decoration-line: underline;
				text-underline-position: under;
			`,
		},
	},
}
